<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Create New Owner')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.name"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('Name')"
                />
                <small
                  v-if="errors.name"
                  class="text-danger"
                >{{ errors.name[0] }}</small>
                <label>{{ $t('Name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.phone"
                  class="mt-2"
                  :state="errors.phone ?false:null"
                  :placeholder="$t('Phone')"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ errors.phone[0] }}</small>
                <label>{{ $t('Phone') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.identify"
                  class="mt-2"
                  :state="errors.identify ?false:null"
                  :placeholder="$t('identifyNo')"
                />
                <small
                  v-if="errors.identify"
                  class="text-danger"
                >{{ errors.identify[0] }}</small>
                <label>{{ $t('identifyNo') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Identification_type') }}</label>
                <v-select
                  v-model="data.identification_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="types=>types.key"
                  :options="types"
                />
                <small
                  v-if="errors.identification_type"
                  class="text-danger"
                >{{ errors.identification_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="data.adress"
                  class="mt-2"
                  :state="errors.adress ?false:null"
                  :placeholder="$t('adress')"
                />
                <small
                  v-if="errors.adress"
                  class="text-danger"
                >{{ errors.adress[0] }}</small>
                <label>{{ $t('adress') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-textarea
                  v-model="data.currentAdres"
                  class="mt-2"
                  :state="errors.currentAdres ?false:null"
                  :placeholder="$t('currentAdres')"
                />
                <small
                  v-if="errors.currentAdres"
                  class="text-danger"
                >{{ errors.currentAdres[0] }}</small>
                <label>{{ $t('currentAdres') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.placeNo"
                  class="mt-2"
                  :state="errors.placeNo ?false:null"
                  :placeholder="$t('placeNo')"
                />
                <small
                  v-if="errors.placeNo"
                  class="text-danger"
                >{{ errors.placeNo[0] }}</small>
                <label>{{ $t('placeNo') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    types() {
      return [{ key: 'identify', title: this.$t('identifyType') }, { key: 'passport', title: this.$t('passport') }, { key: 'other', title: this.$t('other') }]
    },
  },
  created() {},
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.data.owner_type = true
      this.$store.dispatch('owner/Create', this.data).then(() => {
        this.data = {}
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
